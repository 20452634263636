import {ApiService} from "@/api/ApiService";

const baseUrl = process.env.VUE_APP_IFRAME_CLIENT_BASE_URL

export const TestFlowService = {
    getFlow(hash:string) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${baseUrl}/v1/test-flow`, hash)
                .then(response => resolve(response.data))
                .catch(errors => resolve(errors))
        })
    },

    createSession(data: any) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${baseUrl}/v1/sessions`, data)
              .then(response => resolve(response.data))
              .catch(errors => reject(errors))
        })
    },

    createSessionStep(sessionId: number, data: any) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${baseUrl}/v1/sessions/${sessionId}/step`, data)
              .then(response => resolve(response.data))
              .catch(errors => reject(errors))
        })
    },

    createActionRecord(data: any) {
        return new Promise((resolve, reject) => {
            ApiService.post(`${baseUrl}/v1/sessions/action-record`, data)
              .then(response => resolve(response.data))
              .catch(errors => reject(errors))
        })
    }
}
