<template>
  <div v-if="isLoading">
    <LoadingPlaceholder/>
  </div>
  <div v-else class="iframe-content">
    <component :is="`style`">
      .tonal-test-btn, .noise-test-item, .btn-primary {background-color: {{ primaryBgColor }}; border-color:
      {{ primaryBgColor }}; font-weight: bold; border: 2px solid}
      .tonal-test-btn, .noise-test-item {border: none !important}


      .btn-answer-option { background-color: white; border-color: {{ secondaryColor }}; color: {{ secondaryColor }} }
      .btn-answer-option:focus { background-color: {{ secondaryColor }} !important; border-color: {{ secondaryColor }};
      color: white }

      @media (hover: none) {
      .previous-btn:hover {background-color: white !important; border-color: {{ primaryBgColor }} !important; color:
      {{ primaryBgColor }} !important; outline-color: {{ primaryBgColor }} !important; font-weight: bold !important}
      .btn-answer-option:hover { background-color: white !important; border-color: {{ secondaryColor }} !important;
      color: {{ secondaryColor }} !important}
      .btn-answer-option-selected:hover { background-color: {{ secondaryColor }} !important; border-color:
      {{ secondaryColor }}; color: white !important }
      }

      .tonal-test-btn-selected, .btn-noise-item-selected { background-color: {{ primaryDark50BgColor }}!important;
      border-color: {{ primaryDark50BgColor }}; color: white }
      .tonal-test-btn-selected:hover, .btn-noise-item-selected:hover { background-color: {{ primaryDark20BgColor }};
      border-color: {{ primaryDark50BgColor }}; color: white }

      @media (hover: hover) {
      .output-button:hover {background-color: {{ primaryDark20BgColor }}; border-color: unset;}
      .tonal-test-btn:hover, .noise-test-item:hover, .btn-primary:hover {background-color: {{ primaryDark20BgColor }};
      border-color: {{ primaryDark20BgColor }}; font-weight: bold}
      .btn-answer-option-selected:hover { background-color: {{ secondaryLight20Color }}!important; border-color:
      {{ secondaryLight20Color }}; color: white }
      }
      .tonal-test-btn:focus, .noise-test-item:focus, .btn-primary:focus {background-color: {{ primaryBgColor }};
      border-color: {{ primaryBgColor }}; font-weight: bold; border: 2px solid; box-shadow: unset !important}
      .tonal-test-btn:active, .noise-test-item:active, .btn-primary:active {background-color: {{
        primaryDark50BgColor
      }}; border-color: {{ primaryDark50BgColor }}; box-shadow: unset !important, font-weight: bold}


      .btn-primary.disabled {background-color: #EAEAEA; color: #787878; font-weight: bold; border-color: #EAEAEA;
      opacity: 100% !important;}

      .btn-answer-option:hover { background-color: {{ secondaryLight20Color }}; border-color: {{
        secondaryLight20Color
      }}; color: white;}
      .btn-answer-option:active {background-color: {{ secondaryColor }} ; border-color: {{ secondaryColor }};
      box-shadow: unset !important}
      .btn-answer-option-selected { background-color: {{ secondaryColor }}!important; border-color: {{
        secondaryColor
      }}; color: white }


      .output-button:focus {background-color: {{ primaryDark50BgColor }} !important; box-shadow: unset !important }
      .output-button:active {background-color: {{ primaryDark50BgColor }} !important; box-shadow: unset !important }

      .checkbox-round:checked { background-color: {{ secondaryColor }}; border: 2px solid {{ secondaryColor }}; }
      .dropdown-selected { background-color: {{ secondaryColor }}; color: white; }
      .previous-btn { background-color: white; color: {{ primaryBgColor }}; outline-color: {{ primaryBgColor }};
      font-weight: bold}


      .radio-button:checked { background-color: {{ secondaryColor }}; }

      .btn-check:focus {box-shadow: unset;}
      .btn-check:check {box-shadow: unset;}

      .multiselect-option.is-selected.is-pointed { background: {{ secondaryColor }} !important; }
      .multiselect-option.is-selected { background: {{ secondaryColor }} !important; }
      .multiselect.is-active { box-shadow: unset !important; }
      .multiselect-selected {background: {{ secondaryColor }}; color: white;}
      .multiselect-selected .multiselect-clear-icon {background: white;}
      .multiselect-selected .multiselect-caret {background: white;}
      .multiselect-option {color: black}

      .left-waves {background-color: {{ secondaryColor }} }
      .right-waves {background-color: {{ secondaryColor }} }
      .clickable-text:hover {color: {{ primaryDark20BgColor }} }

      .outer-ring{ background-color: {{ secondaryColor + "40" }} }
      .outer-ring-2{ background-color: {{ secondaryColor + "59" }} }
      .outer-ring-3{ background-color: {{ secondaryColor + "73" }} }
      .main-ring{ background-color: {{ secondaryColor }} }

      .primary-bg-color {background-color: {{ primaryBgColor }};}
      .primary-progress-bar-bg-color {background-color: {{ primaryBgColor + "59" }} }
      .secondary-bg-color {background-color: {{ secondaryColor }} }


      input[type='radio']:checked:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -4px;
      left: -1px;
      position: relative;
      background-color: {{ secondaryColor }};
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px;
      }

      input[type='radio']:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -4px;
      left: -1px;
      position: relative;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 2px solid #BCBCBC;
      }

    </component>
    <div class="content">
      <router-view/>
    </div>

    <div v-if="footerImages.length > 0" class="evollu-row footer-row">
      <div class="evollu-row">
        <div
            :key="idx"
            v-for="(imgUrl, idx) in footerImages"
            class="img-wrapper"
        >
          <!--<div class="separator" v-if="idx === 1"/-->
          <div :class="{ separator: idx === 1 }">
            <img
                :src="imgUrl"
                class="footerImage"
                :class="{ iml20: idx === 1 }"
            />
          </div>
        </div>
      </div>
      <!--<div class= :key="image.id" v-for="image in footerImages" style="height: 100px;">
        <img :src="image.url">
        <div class="vl"/>
      </div>-->
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {TestFlowService} from "@/api/TestFlowService";
import {InfoPage as InfoPageInterface} from '@/Interfaces/InfoPage';
import {CustomColors, GeneralFlowInfo,} from "@/Interfaces/GeneralFlowInfo";
import LoadingPlaceholder from "@/components/Common/LoadingPlaceholder.vue";
import {PageTypes} from "@/Enums/PageTypes";
import {routes} from "@/Enums/Routes";
import {FormPage} from "@/Interfaces/FormPage";
import {FormField} from "@/Interfaces/FormField";
import {Question, UserInfoPage} from "@/Interfaces/UserInfoPage";
import {ThankYouPage} from "@/Interfaces/ThankYouPage";
import {TonalTestPage} from "@/Interfaces/TonalTestPage";
import {HearingTestTypes} from "@/Enums/HearingTestTypes";
import {SoundOutputPage} from "@/Interfaces/SoundOutputPage";
import {PreparationPage} from "@/Interfaces/PreparationPage";
import {QuestionnairePage} from "@/Interfaces/QuestionnairePage";
import {NoiseTestPage} from "@/Interfaces/NoiseTestPage";
import {NoiseTestItem} from "@/Interfaces/NoiseTestItem";
import {ErrorNotificationPage} from "@/Interfaces/ErrorNotificationPage";
import {ConsentDataOption, ConsentDataOptionType} from "@/Interfaces/ConsentData";
import {mapState} from 'vuex';
import {SolsticioPage} from "@/Interfaces/SolsticioPage";
import {TonalTestResultPage} from "@/Interfaces/TonalTestResultPage";
import {NoiseTestResultPage} from "@/Interfaces/NoiseTestResultPage";

export default defineComponent({
  components: {LoadingPlaceholder},
  data() {
    return {
      hash: "",
      isLoading: false as boolean,
      footerImages: Array<any>(),
      primaryBgColor: "",
      primaryDark20BgColor: "",
      primaryDark40BgColor: "",
      primaryDark50BgColor: "",
      secondaryColor: "",
      secondaryLight20Color: "",
      generalFlowPageIndex: 1,
    };
  },
  updated() {
    const autoScroll = this.$store.state.generalFlowInfo.autoScrollToBeginOfPage
    if(autoScroll) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      if(this.$store.state.currentPageIndex && this.$store.state.currentPageIndex > 1) {
        window.parent.postMessage('scrollToTopOfIframe', '*');
      }

    }
  },
  computed: {
    ...mapState([
      'showErrorNotification',
      'finishLoading',

    ]),
  },
  async created() {
    await this.customCreated();
  },
  methods: {
    async customCreated() {
      if (this.$route.query.hash) {
        this.hash = this.$route.query.hash as string;
      }
      //this.hash = "evollusimple"
      //  "88F444B5AED9EECA3B298F9B1199353BB028FD30217DAE3266AEF384847DA338";

      if (!this.hash || this.hash === "") {
        const overrideDefaultUrl = localStorage.getItem("overrideRedirectUrl")
        window.location.href = overrideDefaultUrl !== null && overrideDefaultUrl !== '' ? overrideDefaultUrl : "https://evollu.com";
      } else {
        this.isLoading = true;
        const data: any = await TestFlowService.getFlow(this.hash);

        data.pages = data.pages || []

        data.pages.unshift({
          id: data.pages.length,
          type: 'error_notification_page',
          case: false,
        })

        const colors: CustomColors = {
          ...data.colors,
        };

        let consentDataArray: Array<ConsentDataOption> = []
        let consentIdCounter = 0;
        if (data.acceptance_text && data.acceptance_text_order !== null) {
          consentDataArray.push({
            id: consentIdCounter,
            text: data.acceptance_text,
            order: data.acceptance_text_order,
            type: ConsentDataOptionType.ACCEPTANCE,
            mandatory: true
          })
          ++consentIdCounter
        }
        if (data.marketing_text && data.marketing_text_order !== null) {
          consentDataArray.push({
            id: consentIdCounter,
            text: data.marketing_text,
            order: data.marketing_text_order,
            type: ConsentDataOptionType.MARKETING,
            mandatory: false
          })
          ++consentIdCounter
        }
        if (data.to_partner_text && data.to_partner_text_order !== null) {
          consentDataArray.push({
            id: consentIdCounter,
            text: data.to_partner_text,
            order: data.to_partner_text_order,
            type: ConsentDataOptionType.TOPARTNER,
            mandatory: false
          })
          ++consentIdCounter
        }

        const generalFlow: GeneralFlowInfo = {
          colors: colors,
          logos: data.logos,
          textSize: data.text_size,
          title: data.title,
          lang: data.lang,
          client_id: data.client_id,
          country: data.country,
          consentIntro: data.consent_intro,
          consentData: consentDataArray,
          adjustVolumeText: data.adjust_volume_text,
          adjustVolumeAudioUrl: data.adjust_volume_audio,
          termsText: data.terms_text,
          termsUrl: data.terms_url,
          defaultOutputSource: data.default_output_source,
          overrideRedirectUrl: data.override_redirect_url,
          autoScrollToBeginOfPage:  data.auto_scroll_to_begin_of_page,
          pages: [],
        };

        this.$store.commit('setUseLocalStorageForFormData', data.use_local_storage_for_form_data)

        //If overrideRedirectUrl is not null, store it in the local storage
        if(generalFlow.overrideRedirectUrl !== null) {
          localStorage.setItem('overrideRedirectUrl', generalFlow.overrideRedirectUrl);
        } else {
          localStorage.removeItem('overrideRedirectUrl')
        }

        if (this.$store.state.supportedLang.includes(generalFlow.lang)) {
          this.setLanguage(generalFlow.lang.toLowerCase());
        } else {
          const lang = (navigator.language).split('-')[0]
          if (lang === "en" || lang === 'pt' || lang === 'es') {
            this.setLanguage(lang);
          } else {
            this.setLanguage("en");
          }
        }

        this.footerImages = generalFlow.logos || [];

        this.setColors(colors);

        //handle pages
        generalFlow.pages = this.handlePages(data.pages);


        this.$store.commit("handleGeneralFlowInfo", generalFlow);

        this.$store.commit("updateCurrentPageIndex", this.generalFlowPageIndex);

        //Create a new session
        const sessionId : any = await TestFlowService.createSession({hash: this.hash})
        this.$store.commit("setSessionId", sessionId.id);
        this.isLoading = false;
        const page = generalFlow.pages[this.generalFlowPageIndex]
        if(page.id) {
          TestFlowService.createSessionStep(this.$store.state.iframeSessionId,{"pageId": Number(page.id) })
            .then(res => {
            })
            .catch(err => {
              console.log(err)
            })
        }
        await this.$router.push({
          name: generalFlow.pages[this.generalFlowPageIndex].route,
          params: {hash: this.hash},
        });

        //await this.$router.push({name: 'UserInfoPage', params: {hash: this.hash}})
        //await this.$router.push({name: 'PreparationPage', params: {secondaryBgColor: this.secondaryColor}})
        //await this.$router.push({name: 'SoundOutputPage', params: {primaryBgColor: this.primaryBgColor}})

        this.isLoading = false;
      }
    },
    setLanguage(locale: string) {
      this.$i18n.locale = locale;
    },
    setColors(colors: CustomColors) {
      this.primaryBgColor = colors.primary;
      this.primaryDark20BgColor = colors.primary_dark_20;
      this.primaryDark40BgColor = colors.primary_dark_40;
      this.primaryDark50BgColor = colors.primary_dark_50;
      this.secondaryColor = colors.secondary;
      this.secondaryLight20Color = colors.secondary_light_20;
    },
    handlePages(pages: Array<any>) {
      let instatiatedPages: (InfoPageInterface | FormPage | UserInfoPage | ThankYouPage | TonalTestPage | SoundOutputPage | PreparationPage | QuestionnairePage | NoiseTestPage | ErrorNotificationPage | SolsticioPage | TonalTestResultPage | NoiseTestResultPage)[] = []
      for (let page of pages) {
        switch (page.type) {
            // --------------------------------------
            // ----------- INFO PAGE ----------------
            // --------------------------------------
          case PageTypes.INFO_PAGE : {
            const pg: InfoPageInterface = {
              id: page.id,
              route: routes.INFO_PAGE,
              title: page.title,
              description: page.subtitle,
              subtitle: page.sub_subtitle,
              instructions: page.info_items,
              buttonText: this.$t('start'),
              formData: null,
              type: PageTypes.INFO_PAGE
            }
            instatiatedPages.push(pg)
            break;
          }
            // --------------------------------------
            // ---------- CONTACT PAGE --------------
            // --------------------------------------
          case PageTypes.CONTACT_PAGE: {
            const fields = page.fields.map((elem: any) => {
              let field: FormField = {
                id: elem.id,
                name: elem.name,
                placeholder: elem.placeholder,
                mandatory: elem.mandatory as boolean,
                dataValidation: elem.data_validation
              };
              return field;
            });

            const pg: FormPage = {
              id: page.id,
              route: routes.CONTACT_PAGE,
              title: page.title,
              subtitle: page.subtitle,
              consentOptions: page.consent_options as boolean,
              overrideButtonPreviousAction: page.override_button_previous_action,
              overrideButtonPreviousTitle: page.override_button_previous_title,
              fields: fields,
              formData: null,
              type: PageTypes.CONTACT_PAGE
            }

            instatiatedPages.push(pg);
            break;
          }
            // --------------------------------------
            // -------- USER INFO PAGE --------------
            // --------------------------------------
          case PageTypes.USER_INFO_PAGE: {
            const questions = page.questions.map((elem: any) => {
              const question: Question = {
                id: elem.id,
                question: elem.question,
                options: elem.options.map((opt: any) => {
                  return {id: opt.id, text: opt.text};
                }),
              };
              return question;
            });

            const pg: UserInfoPage = {
              id: page.id,
              route: routes.USER_INFO_PAGE,
              title: page.title,
              subtitle: page.subtitle,
              questions: questions,
              hasGenderQuestion: page.show_genre as boolean,
              showNotSpecifiedGenre: page.show_not_specified_genre as boolean,
              formData: null,
              type: PageTypes.USER_INFO_PAGE
            }

            instatiatedPages.push(pg);
            break;
          }

            // --------------------------------------
            // -------- THANK YOU PAGE --------------
            // --------------------------------------
          case PageTypes.THANK_YOU_PAGE: {
            const pg: ThankYouPage = {
              id: page.id,
              route: routes.THANK_YOU_PAGE,
              title: page.title,
              subtitle: page.subtitle,
              message: page.message,
              formData: null,
              ctaText: page.cta_text,
              ctaUrl: page.cta_url,
              openCtaOnNewTab: page.open_cta_on_new_tab,
              type: PageTypes.THANK_YOU_PAGE
            }

            instatiatedPages.push(pg);
            break;
          }

            // --------------------------------------
            // -------- QUESTIONNAIRE PAGE --------------
            // --------------------------------------
          case PageTypes.QUESTIONNAIRE_PAGE: {
            for (let question of page.questions) {
              const pg: QuestionnairePage = {
                id: page.id,
                route: routes.QUESTIONNAIRE_PAGE,
                title: page.title,
                subtitle: page.subtitle,
                question: question,
                formData: null,
                totalQuestions: page.questions.length,
                currentQuestionIdx: 0,
                questionnairePageId: page.id,
                type: PageTypes.QUESTIONNAIRE_PAGE
              };

              instatiatedPages.push(pg);
            }
            break;
          }
            // --------------------------------------
            // ----------- ERROR NOTIFICATION PAGE ----------------
            // --------------------------------------
          case PageTypes.ERROR_NOTIFICATION_PAGE : {
            const pg: ErrorNotificationPage = {
              id: page.id,
              route: routes.ERROR_NOTIFICATION_PAGE,
              case: page.case,
              messageErrorHead: this.$t('unable_to_load_test'),
              messageErrorBody: this.$t('reload_at_the_button'),
              messageInfoHead: this.$t('test_is_not_available'),
              messageInfoBody: this.$t('find_out_more'),
              buttonTextError: this.$t('reload'),
              buttonTextInfo: this.$t('know_more'),
              formData: null,
              type: PageTypes.ERROR_NOTIFICATION_PAGE
            }
            instatiatedPages.push(pg)
            break;
          }

            // --------------------------------------
            // ----------- SOLSTICIO PAGE -----------
            // --------------------------------------
          case PageTypes.SOLSTICIO : {
            const pg: SolsticioPage = {
              id: page.id,
              route: routes.SOLSTICIO_PAGE,
              title: page.title,
              subtitle: page.subtitle,
              formData: null,
              type: PageTypes.SOLSTICIO
            }
            instatiatedPages.push(pg)
            break;
          }

            // --------------------------------------
            // ----------- Tonal Test Result PAGE ---
            // --------------------------------------
          case PageTypes.TONAL_TEST_RESULT_PAGE : {
            const pg: TonalTestResultPage = {
              id: page.id,
              route: routes.TONAL_TEST_RESULT_PAGE,
              title: page.title,
              subtitle: page.subtitle,
              formData: null,
              type: PageTypes.TONAL_TEST_RESULT_PAGE
            }
            instatiatedPages.push(pg)
            break;
          }

            // --------------------------------------
            // ----------- Tonal Test Result PAGE ---
            // --------------------------------------
          case PageTypes.NOISE_TEST_RESULT_PAGE : {
            const pg: NoiseTestResultPage = {
              id: page.id,
              route: routes.NOISE_TEST_RESULT_PAGE,
              title: page.title,
              subtitle: page.subtitle,
              formData: null,
              type: PageTypes.NOISE_TEST_RESULT_PAGE
            }
            instatiatedPages.push(pg)
            break;
          }

            // --------------------------------------
            // -------- HEARING TEST PAGES ----------
            // --------------------------------------
          case PageTypes.HEARING_TEST_PAGE : {

            //Check if the sound output page is already present, if not, put it before the first test
            if (!instatiatedPages.some(elem => {
              return elem.type === PageTypes.OUTPUT_PAGE
            })) {
              const pg: SoundOutputPage = {
                id: page.id,
                title: page.title,
                subtitle: page.subtitle,
                route: routes.SOUNDOUTPUT_PAGE,
                formData: null,
                type: PageTypes.OUTPUT_PAGE
              }

              instatiatedPages.push(pg)
            }


            //save prepare attenuation
            if (this.$store.state.originalPrepareAttenuation === null) {
              this.$store.commit("setOriginalPrepareAttenuation", page.prepare_attenuation);
              //Add the preparation page to the first test, add it before the 1st test and output page
              const pg: PreparationPage = {
                id: page.id,
                title: page.title,
                subtitle: page.subtitle,
                route: routes.PREPARATION_PAGE,
                formData: null,
                type: PageTypes.PREPARATION_PAGE
              }

              instatiatedPages.push(pg)
            } else {
              //prepare attenuation already exists, one Hearing Test has been pushed already
              if (this.$store.state.originalPrepareAttenuation !== page.prepare_attenuation) {
                //preparation attenuation has changed, add a new preparation page
                const pg: PreparationPage = {
                  id: page.id,
                  title: page.title,
                  subtitle: page.subtitle,
                  route: routes.PREPARATION_PAGE,
                  formData: null,
                  type: PageTypes.PREPARATION_PAGE
                }

                instatiatedPages.push(pg)
              }
            }

            switch (page.hearing_test_type) {
                // --------- NOISE --------
              case HearingTestTypes.NOISE: {
                const pg: NoiseTestPage = {
                  id: page.id,
                  route: routes.NOISE_TEST_PAGE,
                  title: page.title,
                  subtitle: page.subtitle,
                  ctaPass: page.cta_pass,
                  passText: page.pass_text,
                  referText: page.refer_text,
                  config: {
                    noise_test_id: page.id,
                    incrementStep: page.increment_step || 5,
                    prepareAttenuation: page.prepare_attenuation || 25,
                    noiseStart: page.noise_start,
                    noiseEnd: page.noise_end,
                    sound_noise_threshold: page.sound_noise_threshold,
                    items: page.items.map((item: NoiseTestItem) => {
                      const ntItem: NoiseTestItem = {
                        id: item.id,
                        icon: item.icon,
                        sound: item.sound
                      }
                      return ntItem
                    }),
                  },
                  formData: null,
                  type: PageTypes.NOISE_TEST_PAGE
                }

                instatiatedPages.push(pg)
                break;
              }

                // --------- TONAL --------
              case HearingTestTypes.TONAL: {
                const pg: TonalTestPage = {
                  id: page.id,
                  route: routes.TONAL_TEST_PAGE,
                  title: page.title,
                  subtitle: page.subtitle,
                  config: {
                    prepareAttenuation: page.prepare_attenuation || 25,
                    maxAttenuation: page.max_attenuation || 80,
                    minAttenuation: page.min_attenuation || 0,
                    incrementStep: page.increment_step || 5,
                    silenceSeconds: page.silence_seconds || 1,
                    stimulusSeconds: page.stimulus_seconds || 3,
                    thresholdDiff: page.threshold_diff || 30,
                    hearingThresholdAvg: page.hearing_threshold_avg || 40,
                    maxOutput: page.max_output,
                    frequencies: page.frequencies,
                  },
                  formData: null,
                  type: PageTypes.HEARING_TEST_PAGE,
                  normalHlText: page.normal_hl_text,
                  mildHlText: page.mild_hl_text,
                  severeHlText: page.severe_hl_text,
                  contactMessage: page.contact_message
                }
                instatiatedPages.push(pg)
                break;
              }
            }
          }


        }
      }

      return instatiatedPages;
    },
  },
  watch: {
    '$route.query.hash'(val) {
      if (val !== undefined) {
        this.customCreated()
      }
    },
    async showErrorNotification(val) {
      if (val) {
        this.generalFlowPageIndex = 0
        // this.customCreated();
      } else {
        this.generalFlowPageIndex = 1
      }
    },
    async finishLoading(val) {
      if (val) {
        this.isLoading = false
      }
    }
  }
});
</script>

<style scoped>
.separator {
  border-left: 2px solid #8E99B4;
  max-height: 40px;
  display: inline-block;
  margin-left: 20px;
}

.footerImage {
  max-height: 40px;
  max-width: 140px;
}

.iml20 {
  margin-left: 20px;
}

.evollu-row {
  display: flex;
  justify-content: center;
}

.footer-row {
  /*margin-top: 50px;*/
}

.img-wrapper {
  display: flex;
  align-items: center;
}
</style>
